<template>

<!-- 校园筛查 筛查计划管理 回收站 -->
  <div class="page">
    <div class="header-container">
      <div class="header-section">
        <p class="route-name">
          | {{routerName}}
        </p>
        <div class="button-section">
          <el-button size="mini"  @click="goTo('/detection/schoolList')">
            返回
            </el-button>
         </div>
      </div>
    </div>
    <div class="header">
      <div class="header-input">
        <el-select v-model="schoolId" size="mini" filterable :filter-method="searchSchool" placeholder="请选择学校" @change="getGradeSelect">
          <el-option
            v-for="item in schoolOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="gradeId" size="mini" placeholder="请选择年级" @change="getClassSelect">
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="classId" size="mini" placeholder="请选择班级"  @change="search">
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
        maxlength="11"
          placeholder="请输入学生姓名搜索"
          size="mini"
          class="search"
          v-model="searchValue"
          style="width:250px;"
        >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search()" style="cursor:pointer"></i>
        </el-input>
      </div>
      <div class="header-search">
      </div>
    </div>

    <div class="content">
      <div class="table-main">
        <el-table :data="tablePageData" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" style="width: 100%;"
        >
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="childrenName" label="姓名" width="150" :formatter="formNull" >
          </el-table-column>
          <el-table-column prop="studentId" label="学号" :formatter="formNull" width="110" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" :formatter="formNull" width="120" align="center">
          </el-table-column>
          <el-table-column prop="birthday" label="出生日期" :formatter="formatterDate" width="120" align="center">
          </el-table-column>
          <el-table-column prop="idCard" label="身份证号" :formatter="formNull"  align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系手机号" :formatter="formNull" width="120" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校" :formatter="formNull" width="120" align="center">
          </el-table-column>
          <el-table-column prop="grade" label="年级" :formatter="formNull" width="120" align="center">
          </el-table-column>
          <el-table-column prop="className" label="班级" :formatter="formNull" width="120" align="center">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="small" type="text" style="color: #86B394;" slot="reference" @click="handleRecover(0,scope.row.childrenId)">恢复</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{'marginTop' : '52px'}">
              <img src="@/assets/detection/image/404.png" alt="" width="333">
            </div>
          </div>
        </el-table>
        <div class="table-page">
          <el-button
            :type="batchNum?'primary':'disabled'"
            :disabled="batchNum?false:true"
            size="mini"
            class="delete-button" round
            @click = "handleRecover(1)"
          >
            批量恢复({{ batchNum }})
          </el-button>
          <pagination :current-page="startPage" :total="totalPage"  @currentChange="handleCurrentChange" @sizeChange="sizeChange"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import selectService from '@/globals/service/detection/select.js'
import schoolService from '@/globals/service/detection/school.js'
import otherService from '@/globals/service/detection/other.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      schoolId: '',
      gradeId: null,
      classId: null,
      school: '',
      newGradeId: null,
      schoolClassId: null,
      nickName: '',
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      startPageSchool: 1,
      pageSizeSchool: 1000,
      searchValue: '',
      total: 0,
      totalPage: 0,
      startPage: 1,
      pageSize: 8,
      tableData: [],
      tablePageData: [],
      resData: [],
      multipleSelection: [],
      batchNum: 0
    }
  },
  mounted () {
    this.getSchoolSelect()
  },
  created () {
    this.routerName = this.$route.meta.title ? this.$route.meta.title : ''
    const schoolId = storage.get('schoolId')
    if (schoolId) {
      this.schoolId = schoolId
      // this.getListData()
    }
  },
  methods: {
    sizeChange (val) {
      this.pageSize = val
      this.getListData()
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    searchSchool () {
      schoolService.search({ name: '' }).then(res => {
        const schoolList = res.data || []
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    getSchoolSelect () {
      selectService.school({ startPage: this.startPageSchool, pageSize: this.pageSizeSchool }).then(res => {
        this.schoolId = ''
        this.gradeId = null
        this.classId = null
        this.schoolOptions = []
        this.gradeOptions = []
        this.classOptions = []

        const schoolId = storage.get('schoolId')
        if (schoolId) {
          const arr = []
          res.data.schoolList.forEach(item => {
            if (Number(item.id) === Number(schoolId)) {
              arr.push({
                value: Number(item.id),
                label: item.schoolName
              })
            }
          })
          this.schoolId = Number(schoolId)
          this.schoolOptions = arr
          return this.getGradeSelect()
        }
        const schoolList = res.data.schoolList
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        if (schoolOptions.length > 0) {
          this.schoolId = schoolOptions[0].value
          this.school = schoolOptions[0].value
          this.getListData()
        } else {
          this.$alert('未找到可选的学校，请添加', '提示', {
            type: 'info',
            center: true
          })
        }
        this.schoolOptions = schoolOptions
        this.getGradeSelect()
      })
    },
    getGradeSelect () {
      if (!this.schoolId) {
        return false
      }
      // this.searchSchool()
      this.gradeId = null
      this.classId = null
      this.gradeOptions = []
      this.classOptions = []
      selectService.grade({ schoolId: this.schoolId + '' }).then(res => {
        const gradeList = res.data
        const gradeOptions = []
        gradeList.forEach(item => {
          gradeOptions.push({
            value: Number(item.id),
            label: item.gradeName
          })
        })
        if (gradeOptions.length > 0) {
          this.gradeId = gradeOptions[0].value
        }
        this.gradeOptions = gradeOptions
        this.getClassSelect()
      })
    },
    getClassSelect () {
      if (!this.gradeId) {
        return false
      }
      this.classId = null
      this.classOptions = []
      selectService.class({ gradeId: this.gradeId + '' }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: Number(item.id),
            label: item.className
          })
        })
        if (classOptions.length > 0) {
          this.classId = Number(classOptions[0].value)
        }
        this.classOptions = classOptions
        this.search()
      })
    },
    getListData () {
      const jsonData = {
        school: this.school,
        newGradeId: this.newGradeId,
        schoolClassId: this.schoolClassId,
        nickName: this.nickName,
        startPage: this.startPage,
        pageSize: this.pageSize
      }
      otherService.getDelChildren(jsonData).then(res => {
        if (res.data.totalPage) {
          this.total = res.data.totalPage * this.pageSize
          this.totalPage = res.data.totalPage
          this.tablePageData = res.data.noScreeningList
        } else {
          this.total = 0
          this.tablePageData = []
        }
      })
    },
    search () {
      this.startPage = 1
      this.school = this.schoolId
      this.newGradeId = this.gradeId
      this.schoolClassId = this.classId
      this.nickName = this.searchValue
      this.getListData()
    },

    handleCurrentChange (val) {
      this.startPage = val
      this.getListData()
    },

    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleRecover (type, id) {
      let childrenIdList = []
      if (type === 1) {
        this.$confirm('请确认要撤回选中的全部学生', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const multipleSelection = this.multipleSelection
          multipleSelection.forEach(item => {
            childrenIdList.push(item.childrenId)
          })
          otherService.recyclingChildren({ childrenIdList: childrenIdList }).then(res => {
            this.$message({ type: 'success', message: '撤回成功!' })
            this.getListData()
          })
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消撤回' })
          return false
        })
      } else {
        this.$confirm('此操作将撤回选中的学生, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          childrenIdList = [id]
          otherService.recyclingChildren({ childrenIdList: childrenIdList }).then(res => {
            this.$message({ type: 'success', message: '撤回成功!' })
            this.getListData()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消撤回'
          })
        })
      }
    },
    formNull (row, column, cellValue) {
      if (!cellValue) {
        return '-'
      } else {
        return cellValue
      }
    },
    formatterDate (row, column, cellValue) {
      if (!cellValue) {
        return '-'
      } else {
        return Moment(parseInt(cellValue)).format('YYYY-MM-DD')
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    }
  }
}
</script>
<style lang="scss" scoped>
.page .header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 16px;
  background-color: #fff;
  min-height: 100%;
}
.warp {
  // padding: 0 26px;
  background: #fff;
  border-radius: 8px;

  .warp-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 15px;
    font-weight: 500;
    color: #51B25F;

    span {
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
}
.page .header .el-input,.page .header .el-button{
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-search .search>>>.el-input__inner{
  width: 178px;
  border: 1px solid #4DAF5A;
  border-radius: 18px;
  margin-right: 16px;
}
.header-button{
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.table-main .el-form--inline .el-form-item{
  margin-right: 0;
  margin-bottom: 0;
  width: 48%;
}
.header-switc{
  display: flex;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-size: 14px;
  color: #999999;
}
.header-switc-button{
  padding: 10px 12px;
  border: 1px solid #4DAF5A;
  background-color: #FFFFFF;
}
.header-switc-button.header-switc-button-left{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.header-switc-button.header-switc-button-right{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.header-switc-button.active{
  background-color: #4DAF5A;
  color: #fff;
}
.header-switc-button:hover{
  background-color: #74c17f;
  border-color: #74c17f;
  color: #fff;
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    background-color: #fff;
    min-height: 100%;
  }
  .table-page{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
</style>
