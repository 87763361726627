import axios from './../../request/axios.js'
import api from './../../request/api.js'

const otherService = {
  delChildren: function (params) {
    return axios.post(api.other.delChildren, params)
  },
  getDelChildren: function (params) {
    return axios.post(api.other.getDelChildren, params)
  },
  recyclingChildren: function (params) {
    return axios.post(api.other.recyclingChildren, params)
  }
}
export default otherService
